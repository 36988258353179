import { request } from "../api/config"

export const getMediaPath = async (type, url) => {
  let payload = {
    type: type,
    url: url
  }

  let fullpath = '';
  await request.get('/media/fullpath', {params: payload}).then(({data}) => {
    fullpath = data?.fullpath;
  })
  return fullpath;
}