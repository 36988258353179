import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Loading from "../layouts/loading";
import useAsesmen from "../stores/asesmen";

const PrivateRoute = ({ children }) => {
  const { fetchProgress, granted, isLoaded } = useAsesmen((state) => state);

  useEffect(() => {
    fetchProgress();
  }, []);

  if (isLoaded === true) {
    <Loading text="Sedang memuat data..." />;
  } else {
    if (!granted) {
      return <Navigate to="/" />;
    }
    return <>{children}</>;
  }
};

export default PrivateRoute;
