import { Button, Modal, Spinner, Tabs } from "flowbite-react";
import { useEffect, useState } from "react";
import useAsesmen from "../../stores/asesmen";
import parse from "html-react-parser";
import { request } from "../../api/config";
import useQuestion from "../../stores/question";
// import Countdown, { Timer } from "../../components/countdown";
import WarningIcon from "../../images/logo/warning.svg";
import HelpIcon from "../../images/logo/help.svg";
import { ArrowRight, Minus, Plus } from "lucide-react";

const RincianPetunjuk = ({ data }) => {
  const contentStyle = {
    width: "100%",
    height: "40vh",
    overflow: "auto",
  };

  return (
    <>
      <Tabs.Group aria-label="Tabs with underline" style="underline">
        <Tabs.Item title="Sekilas" active={true}>
          <div style={contentStyle}>
            {parse(data?.data?.intro?.about?.value)}
          </div>
        </Tabs.Item>
      </Tabs.Group>
    </>
  );
};

const PetunjukMcmi = ({ progres }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      testId: progres?.testId,
    };
    await request.post("store-answer", payload);
    window.location.reload();
  };

  return (
    <>
      <div className="max-w-4xl mx-auto pt-20 p-5 text-left z-50">
        <h1 className="text-2xl flex gap-2 items-center">
          <img
            style={{ width: "40px", height: "40px" }}
            src={WarningIcon}
            alt="logo warning"
          />
          Harap baca petunjuk berikut sebelum mengerjakan!
        </h1>
        <div className="mt-5 text-lg leading-8">
          <RincianPetunjuk data={progres} />
        </div>

        <div className="mt-3 w-full flex justify-end">
          {isLoading === true ? (
            <>
              <Button className="px-10" disabled>
                <Spinner aria-label="Spinner button example" />
                <span className="pl-3 text-lg">Memproses...</span>
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleSubmit} className="px-10" type="submit">
                <span className="text-lg">Mulai</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const TestSheet = ({ progres }) => {
  const { isLoaded, numbers, fetchNumbers } = useQuestion((state) => state);

  const [question, setQuestion] = useState(progres?.data?.question);
  const [answers, setAnswers] = useState(progres?.data?.answers);
  const [showModal, setShowModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [questionId, setQuestionId] = useState(progres?.questionId);
  const [current, setCurrent] = useState(progres?.number?.current);
  const [answered, setAnswered] = useState(null);
  const [isAllAnswered, setIsAllAnswered] = useState(progres?.isFinish);
  const [minNumber, setMinNumber] = useState(0);
  const [maxNumber, setMaxNumber] = useState(20);

  const handleSubmitAnswer = async (val) => {
    setLoadingSubmit(true);
    setAnswered(null);
    let payload = {
      testId: progres?.testId ?? null,
      questionId: questionId ?? null,
      value: val ? (typeof val !== "string" ? val.toString() : val) : "",
    };

    await request
      .post("store-answer", payload)
      .then((res) => {
        setQuestion(res?.data?.data?.question);
        setAnswers(res?.data?.data?.answers);
        setQuestionId(res?.data?.data?.questionId);
        setCurrent(res?.data?.data?.current);
        setIsAllAnswered(res?.data?.data?.isFinish);

        if (res?.data?.data?.current < 20) {
          setMinNumber(0);
          setMaxNumber(20);
        } else if (
          res?.data?.data?.current >= 20 &&
          res?.data?.data?.current <= 548
        ) {
          setMinNumber(current - 10);
          setMaxNumber(current + 10);
        } else {
          setMinNumber(548);
          setMaxNumber(567);
        }
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const getExpiredAt = () => {
    let date = new Date(progres?.expiredAt ?? null);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let adjustTimezone = date.toLocaleString("en-US", {
      timeZone: timezone,
    });

    return adjustTimezone;
  };

  const expiredAt = getExpiredAt();

  const handleGetAnswer = async (questionId, numIndex) => {
    setLoadingSubmit(true);
    setIsAllAnswered(false);
    let params = {
      currentSessionId: progres?.currentTestSessionId ?? null,
      questionId: questionId,
    };

    await request
      .get("get-answer", { params })
      .then((res) => {
        setQuestion(res?.data?.data?.question);
        setAnswers(res?.data?.data?.answers);
        setQuestionId(res?.data?.data?.questionId);
        setCurrent(numIndex);
        setAnswered(res?.data?.answer);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const handleSubmitTemp = async () => {
    setLoadingNext(true);

    await request
      .post(`save-temp/${progres?.currentTestSessionId ?? ""}`)
      .finally(() => {
        window.location.reload();
      });
  };

  const setTimer = () => {
    let expired = new Date(expiredAt).getTime();
    let now = new Date().getTime();
    let distance = expired - now;

    if (distance < 0) {
      handleSubmitTemp();
    }
  };

  useEffect(() => {
    fetchNumbers(progres?.currentTestSessionId);
    const timer = setInterval(() => {
      setTimer();
    }, 1000);
    return () => clearInterval(timer);
  }, [fetchNumbers, progres, loadingSubmit, expiredAt]);

  return (
    <>
      <div className="max-w-4xl p-3 mx-auto pt-20 relative z-50">
        <div className="gap-2">
          {minNumber > 0 && (
            <button
              className={`w-8 h-8 inline-flex hover:bg-green-200 hover:text-green-800 items-center justify-center bg-green-600 text-white`}
            >
              ....
            </button>
          )}
          {numbers?.slice(minNumber, maxNumber).map((num, key) => {
            let clases = "";
            let disabled = false;
            if (current === key + minNumber + 1) {
              clases = "bg-green-600 text-green-50 animate-pulse";
            } else if (num?.answer) {
              clases = "bg-green-600 text-green-50 ";
            } else {
              clases = "bg-gray-100 text-gray-400";
              // disabled = true;
            }

            return (
              <button
                onClick={() => handleGetAnswer(num?.question_id, key + 1)}
                className={`w-8 h-8 inline-flex hover:bg-green-200 hover:text-green-800 items-center justify-center ${clases}`}
                disabled={disabled}
              >
                {key + minNumber + 1}
              </button>
            );
          })}
          {minNumber < 567 && (
            <button
              className={`w-8 h-8 inline-flex hover:bg-green-200 hover:text-green-800 items-center justify-center bg-gray-100 text-gray-400`}
            >
              ....
            </button>
          )}
          {isAllAnswered === true ? (
            <>
              <button
                onClick={() => setIsAllAnswered(true)}
                className={`h-8 px-3 bg-green-600 animate-pulse text-green-50  hover:bg-green-200 hover:text-green-900 inline-flex items-center justify-center`}
              >
                Selesai
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsAllAnswered(true)}
                className={`h-8 px-3 bg-gray-100  hover:bg-green-200 text-gray-400 hover:text-green-900 inline-flex items-center justify-center`}
              >
                Selesai
              </button>
            </>
          )}
        </div>

        {isAllAnswered === true ? (
          <>
            <div className="py-6 mt-8">
              <h1 className="text-3xl">Lanjut ke sesi selanjutnya?</h1>
              <div className="mt-2 block max-w-2xl text-lg mx-auto">
                Anda dapat memeriksa jawaban anda sebelumnya atau dapat
                melanjutkan ke sesi soal berikutnya dengan menekan tombol
                dibawah ini.
              </div>
              <div className="py-6">
                {loadingNext === true ? (
                  <>
                    <button className="bg-blue-300 cursor-not-allowed py-2 px-8 text-lg rounded text-white">
                      <Spinner aria-label="Spinner button example" />
                      <span className="pl-3 text-lg">Memproses...</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={handleSubmitTemp}
                      className="bg-blue-600 hover:bg-blue-700 py-2 px-8 text-lg rounded text-white"
                    >
                      Lanjutkan
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {loadingSubmit === true ? (
              <>
                <div className="mt-4 h-96 flex items-center justify-center">
                  <div className="flex gap-3 items-center">
                    <Spinner aria-label="Default status example" />{" "}
                    <span>Tunggu sebentar...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-12 flex">
                  <div className="w-full">
                    <div className="text-center text-2xl md:text-3xl">
                      {question ? parse(question) : ""}
                    </div>
                    <div className="mt-8 w-full grid grid-cols-2 gap-2 max-w-xl mx-auto">
                      <button
                        onClick={() => handleSubmitAnswer("+")}
                        className={`
                            w-full border flex items-center justify-center border-blue-200  hover:bg-blue-100 text-blue-900 px-2 py-3 rounded-lg mb-2 text-2xl 
                            ${answered === "+" ? "bg-blue-100" : "bg-gray-50"}
                          `}
                      >
                        <Plus size={28} />
                      </button>

                      <button
                        onClick={() => handleSubmitAnswer("-")}
                        className={`
                            w-full border flex items-center justify-center border-blue-200  hover:bg-blue-100 text-blue-900 px-2 py-3 rounded-lg mb-2 text-2xl 
                            ${answered === "-" ? "bg-blue-100" : "bg-gray-50"}
                          `}
                      >
                        <Minus size={28} />
                      </button>
                    </div>
                    {/* <div className="py-4 text-blue-700  flex  justify-end">
                      <div
                        className="inline-flex gap-2 items-center hover:underline cursor-pointer"
                        onClick={() => handleSubmitAnswer("")}
                      >
                        <span>Lewati pertanyaan ini</span>
                        <ArrowRight size={16} />
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div className="fixed bottom-10 left-10">
          <div className="text-left">
            {/* <p className="text-xs ">Sisa Waktu</p>
            <Countdown time={expiredAt} type="minuteSecond" /> */}
          </div>
        </div>
        <div className="fixed bottom-10 right-10">
          <Modal show={showModal} onClose={() => setShowModal(false)}>
            <Modal.Header>Petunjuk</Modal.Header>
            <Modal.Body>
              <RincianPetunjuk data={progres} />
            </Modal.Body>
          </Modal>

          <button
            className="hover:contrast-50"
            onClick={() => setShowModal(true)}
          >
            <img
              src={HelpIcon}
              style={{ width: "60px", height: "60px" }}
              alt=""
            />
          </button>
        </div>
      </div>
    </>
  );
};

const AsesmenMcmi = () => {
  const { progres } = useAsesmen((state) => state);
  const number = progres?.number?.current;

  return (
    <>
      {number === 0 ? (
        <PetunjukMcmi progres={progres} />
      ) : (
        <TestSheet progres={progres} />
      )}
    </>
  );
};

export default AsesmenMcmi;
