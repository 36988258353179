import axios from "axios";
import { getAccessToken } from "../services/auth";

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

request.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }

    const token = getAccessToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Accept-Language"] = "application/json";
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.reload();
    }
    return error;
  }
);
