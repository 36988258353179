import { Spinner } from "flowbite-react"


const Loading = ({text = null}) => {
  return (
    <>
      <div className="w-full h-screen bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <div>
          <Spinner
            aria-label="Large spinner example"
            size="xl"
          /> 
          <br />
          <span className="text-xl text-white block mt-4">{text ?? 'Sedang Memuat'}</span>
        </div>
      </div>
    </>
  )
}

export default Loading;