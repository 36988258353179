import Watermark from "@uiw/react-watermark";
import { lazy, useEffect, useState } from "react";
import useAsesmen from "../../stores/asesmen";
import AsesmenEpps from "./epps";
import Asesmen16pf from "./16pf";
import AsesmenMmpi from "./mmpi";
import AsesmenMc from "./mc";
import AsesmenTiu from "./tiu";
import AsesmenMbti from "./mbti";
import AsesmenDass from "./dass";
import AsesmenSentence from "./sentence";
import AsesmenMmci from "./mcmi";

const LoadingQuestion = lazy(() => import("../../layouts/loadingQuestion"));
const Bio = lazy(() => import("./bio"));
const AsesmenCfit = lazy(() => import("./cfit"));
const DetailParticipant = lazy(() => import("./detail_participant"));
const AsesmenDisc = lazy(() => import("./disc"));
const AsesmenDocs = lazy(() => import("./docs"));
const SessionDone = lazy(() => import("./done"));
const AsesmenGrafis = lazy(() => import("./grafis"));
const AsesmenIst = lazy(() => import("./ist"));
const AsesmenIst2 = lazy(() => import("./ist2"));
const AsesmenIstInput = lazy(() => import("./ist_input"));
const AsesmenIstMe = lazy(() => import("./ist_me"));
const AsesmenKreplin = lazy(() => import("./kreplin"));
const AsesmenMinat = lazy(() => import("./minat"));
const AsesmenMsdt = lazy(() => import("./msdt"));
const AsesmenPapikostik = lazy(() => import("./papikostik"));
const AsesmenPauli = lazy(() => import("./pauli"));
const AsesmenVak = lazy(() => import("./vak"));
const AsesmenRmib = lazy(() => import("./rmib"));

const AsesmenIndex = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(null);

  const { progres, isLoaded, session } = useAsesmen((state) => state);

  const watermark = `ASESMEN LOGOS INDONESIA ${session?.activation_code ?? ""}`;

  useEffect(() => {
    setUpPage();
  }, []);

  const setUpPage = () => {
    if (progres.isSessionDone) {
      setPage(<SessionDone />);
    }

    if (progres.session === "participant_detail") {
      setPage(<DetailParticipant data={data} />);
    }

    if (progres.session === "form") {
      setPage(<Bio />);
    }

    if (progres.session === "ist2") {
      setPage(<AsesmenIst2 />);
    }

    if (progres.session === "ist_input") {
      setPage(<AsesmenIstInput />);
    }

    if (progres.session === "ist_me") {
      setPage(<AsesmenIstMe />);
    }

    if (progres.session === "ist") {
      setPage(<AsesmenIst />);
    }

    if (
      progres.session === "cfit" ||
      progres.session === "cfit2a" ||
      progres.session === "cfit2b"
    ) {
      setPage(<AsesmenCfit />);
    }

    if (progres.session === "kraeplin") {
      setPage(<AsesmenKreplin />);
    }

    if (progres.session === "pauli") {
      setPage(<AsesmenPauli />);
    }

    if (progres.session === "disc") {
      setPage(<AsesmenDisc />);
    }

    if (progres.session === "papi") {
      setPage(<AsesmenPapikostik />);
    }

    if (progres.session === "msdt") {
      setPage(<AsesmenMsdt />);
    }

    if (progres.session === "vak") {
      setPage(<AsesmenVak />);
    }

    if (progres.session === "minat") {
      setPage(<AsesmenMinat />);
    }

    if (progres.session === "docs") {
      setPage(<AsesmenDocs />);
    }

    if (progres.session === "grafis") {
      setPage(<AsesmenGrafis />);
    }

    if (progres.session === "rmib") {
      setPage(<AsesmenRmib />);
    }

    if (progres.session === "epps") {
      setPage(<AsesmenEpps />);
    }

    if (progres.session === "16pf") {
      setPage(<Asesmen16pf />);
    }

    if (progres.session === "mmpi") {
      setPage(<AsesmenMmpi />);
    }

    if (progres.session === "mc") {
      setPage(<AsesmenMc />);
    }

    if (progres.session === "tiu") {
      setPage(<AsesmenTiu />);
    }

    if (progres.session === "mbti") {
      setPage(<AsesmenMbti />);
    }

    if (
      progres.session === "dass" ||
      progres.session === "gad7" ||
      progres.session === "phq9"
    ) {
      setPage(<AsesmenDass />);
    }

    if (progres.session === "sentence") {
      setPage(<AsesmenSentence />);
    }

    if (progres.session === "mcmi") {
      setPage(<AsesmenMmci />);
    }
  };

  return (
    <div className="w-full min-h-screen bg-blue-50">
      <Watermark
        content={watermark}
        className="min-h-screen"
        rotate={0}
        gapX={0}
        width={370}
        gapY={26}
        height={8}
        fontSize={18}
        fontColor="rgb(0 0 0 / 3%)"
      >
        {isLoaded && <LoadingQuestion />}
        {page}
      </Watermark>
    </div>
  );
};

export default AsesmenIndex;
