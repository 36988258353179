import { Button, Modal, Spinner, Tabs } from "flowbite-react";
import { lazy, useEffect, useState } from "react";
import useAsesmen from "../../stores/asesmen";
import { request } from "../../api/config";
import useQuestion from "../../stores/question";
import { Controller, useForm } from "react-hook-form";
import WarningIcon from "../../images/logo/warning.svg";
import HelpIcon from "../../images/logo/help.svg";
import he from "he";

const LoadingQuestion = lazy(() => import("../../layouts/loadingQuestion"));

const RincianPetunjuk = ({ data }) => {
  const contentStyle = {
    width: "100%",
    height: "54vh",
    overflow: "auto",
  };

  return (
    <>
      <Tabs.Group aria-label="Tabs with underline" style="underline">
        <Tabs.Item title="Sekilas" active={true}>
          <div style={contentStyle}>
            {data?.data?.intro?.about?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: he.decode(data?.data?.intro?.about?.value),
                }}
              />
            )}
          </div>
        </Tabs.Item>
      </Tabs.Group>
    </>
  );
};

const PetunjukSentence = ({ progres }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      testId: progres?.testId,
    };
    await request.post("store-answer", payload);
    window.location.reload();
  };

  return (
    <>
      <div className="max-w-4xl mx-auto pt-20 p-5 text-left z-50">
        <h1 className="text-2xl flex gap-2 items-center">
          <img
            style={{ width: "40px", height: "40px" }}
            src={WarningIcon}
            alt="logo warning"
          />
          Harap baca petunjuk berikut sebelum mengerjakan!
        </h1>
        <div className="mt-5 text-lg leading-8">
          <RincianPetunjuk data={progres} />
        </div>

        <div className="mt-3 w-full flex justify-end">
          {isLoading === true ? (
            <>
              <Button className="px-10" disabled>
                <Spinner aria-label="Spinner button example" />
                <span className="pl-3 text-lg">Memproses...</span>
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleSubmit} className="px-10" type="submit">
                <span className="text-lg">Mulai</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const TestSheet = ({ progres }) => {
  const { isLoaded, numbers, fetchNumbers } = useQuestion((state) => state);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(progres?.data?.answers);
  const [showModal, setShowModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(progres?.isFinish);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { control, handleSubmit } = useForm();

  const handleSubmitAnswer = async (data) => {
    setShowConfirm(false);
    setLoadingSubmit(true);
    let payload = {
      answer: JSON.stringify(data?.question),
    };

    await request
      .post(`save-minat/${progres?.currentTestSessionId ?? ""}`, payload)
      .then((res) => {
        setAnswers(res?.data?.data?.answers);
        setIsAllAnswered(res?.data?.data?.isFinish);
      })
      .finally(() => {
        setLoadingSubmit(false);
        window.location.reload();
      });
  };

  const getExpiredAt = () => {
    let date = new Date(progres?.expiredAt ?? null);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let adjustTimezone = date.toLocaleString("en-US", {
      timeZone: timezone,
    });

    return adjustTimezone;
  };

  const expiredAt = getExpiredAt();

  const handleSubmitTemp = async () => {
    setLoadingSubmit(true);

    await request
      .post(`save-temp/${progres?.currentTestSessionId ?? ""}`)
      .finally(() => {
        window.location.reload();
      });
  };

  const setTimer = () => {
    let expired = new Date(expiredAt).getTime();
    let now = new Date().getTime();
    let distance = expired - now;

    if (distance < 0) {
      handleSubmitTemp();
    }
  };

  const getQuestion = async () => {
    setIsLoading(true);
    await request
      .get(`get-all-questions/${progres?.testId}`)
      .then(({ data }) => {
        setQuestions(data?.data);
      });

    setIsLoading(false);
    return;
  };

  useEffect(() => {
    fetchNumbers(progres?.currentTestSessionId);
    getQuestion();

    const timer = setInterval(() => {
      setTimer();
    }, 1000);
    return () => clearInterval(timer);
  }, [fetchNumbers, progres, loadingSubmit, expiredAt]);

  return (
    <>
      <div className="max-w-4xl p-3 mx-auto pt-20 relative z-50">
        {isAllAnswered === true ? (
          <>
            <div className="py-6 mt-8">
              <h1 className="text-3xl">Lanjut ke sesi selanjutnya?</h1>
              <div className="mt-2 block max-w-2xl text-lg mx-auto">
                Anda dapat memeriksa jawaban anda sebelumnya atau dapat
                melanjutkan ke sesi soal berikutnya dengan menekan tombol
                dibawah ini.
              </div>
              <div className="py-6">
                <button
                  onClick={handleSubmitTemp}
                  className="bg-blue-600 hover:bg-blue-700 py-2 px-8 text-lg rounded text-white"
                >
                  Lanjutkan
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {loadingSubmit === true ? (
              <>
                <div className="mt-4 h-96 flex items-center justify-center">
                  <div className="flex gap-3 items-center">
                    <Spinner aria-label="Default status example" />{" "}
                    <span>Tunggu sebentar...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLoading === true ? (
                  <>
                    <LoadingQuestion />
                  </>
                ) : (
                  <>
                    <form action="" onSubmit={handleSubmit(handleSubmitAnswer)}>
                      <div className="w-full text-left max-w-2xl mx-auto">
                        {/* questions will be here  */}
                        <>
                          {questions.map((item, key) => (
                            <div className="py-3 border-b border-gray-300">
                              <p className="text-xl md:text-2xl">
                                {key + 1}. {item.question}
                              </p>
                              <Controller
                                control={control}
                                name={`question.${key}`}
                                render={({ field }) => (
                                  <input
                                    id={`answer-${key}`}
                                    type="text"
                                    // name={`questions[${key}]`}
                                    onChange={(e) =>
                                      field.onChange({
                                        questionId: item.id,
                                        answer: e.target.value,
                                      })
                                    }
                                    placeholder="Ketik jawaban disini"
                                    className="w-full block mt-2 border border-gray-300 p-2 text-lg rounded-lg"
                                  />
                                )}
                              />
                            </div>
                          ))}
                        </>
                        <div className="py-4 mb-24 text-right">
                          <button
                            type="button"
                            onClick={() => setShowConfirm(true)}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className="bg-blue-600 w-full md:w-auto py-2 px-8 rounded-xl text-white hover:bg-blue-700"
                          >
                            Kirim Jawaban
                          </button>
                        </div>

                        <Modal
                          show={showConfirm}
                          size="md"
                          popup={true}
                          onClose={() => setShowConfirm(false)}
                        >
                          <Modal.Header />
                          <Modal.Body>
                            <div className="text-center">
                              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Apakah anda yakin dengan jawaban anda dan ingin
                                mengirimnya?
                              </h3>
                              <div className="flex justify-center gap-4">
                                <button
                                  onClick={handleSubmit(handleSubmitAnswer)}
                                  className="bg-blue-600 text-white rounded py-2 px-16"
                                >
                                  Iya!
                                </button>
                                <button
                                  type="button"
                                  className="bg-white border py-2 px-4 rounded"
                                >
                                  Tidak
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </form>
                  </>
                )}
              </>
            )}
          </>
        )}

        <div className="fixed bottom-10 left-10">
          <div className="text-left">{/*  -- progress ada disini -- */}</div>
        </div>
        <div className="fixed bottom-10 right-10">
          <Modal show={showModal} onClose={() => setShowModal(false)}>
            <Modal.Header>Petunjuk</Modal.Header>
            <Modal.Body>
              <RincianPetunjuk data={progres} />
            </Modal.Body>
          </Modal>

          <button
            className="hover:contrast-50"
            onClick={() => setShowModal(true)}
          >
            <img
              src={HelpIcon}
              style={{ width: "60px", height: "60px" }}
              alt=""
            />
          </button>
        </div>
      </div>
    </>
  );
};

const AsesmenSentence = () => {
  const { progres } = useAsesmen((state) => state);
  const number = progres?.number?.current;

  return (
    <>
      {number === 0 ? (
        <PetunjukSentence progres={progres} />
      ) : (
        <TestSheet progres={progres} />
      )}
    </>
  );
};

export default AsesmenSentence;
