import moment from "moment-timezone";
import { useEffect, useState } from "react";

export const Clock = ({ withTimezone = false }) => {
  const [clock, setClock] = useState(new Date());

  const timezone = moment.tz.guess();
  const refreshClock = () => {
    setClock(new Date());
  };

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <span className="block w-24 border-r pr-2">
          {clock.toLocaleTimeString("en-US", { hour12: false })}{" "}
        </span>
        {withTimezone && <span className="block ml-2">{timezone}</span>}
      </div>
    </>
  );
};
