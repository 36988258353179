import { Button, Spinner } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../api/config";
import { Clock } from "../components/clock";
import { saveLogin } from "../services/auth";
import bg from "./../images/bg-asesmen.jpg";
import logo from "./../images/logo.png";
import VerificationInput from "react-verification-input";
import moment from "moment-timezone";

const bgStyle = {
  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),url(${bg})`,
  backgroundSize: "cover",
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const navigate = useNavigate();

  const checkCode = async (val) => {
    setIsLoading(true);
    if (!val) {
      setIsError(true);
      setErrMessage("Kode aktivasi tidak boleh kosong!");
      setIsLoading(false);
      return;
    }

    let data = {
      code: val,
      timezone: moment.tz.guess(),
    };

    await request
      .post("check-code", data)
      .then((res) => {
        saveLogin(res.data.data);

        // delete all localstorage before
        localStorage.clear("kreplin_answered");
        localStorage.clear("kreplin_currentIndex");
        localStorage.clear("kreplin_currentNum");

        navigate("/challenge");
      })
      .catch((error) => {
        setIsError(true);
        setErrMessage(error.response?.data?.message);
      });

    setIsLoading(false);
  };

  return (
    <div className="w-full h-screen flex items-center" style={bgStyle}>
      <div className="max-w-lg mx-auto">
        <img src={logo} className="h-12 mx-auto" alt="" />
        <div className="bg-white shadow-xl  p-6 rounded-xl mt-6">
          <div className="text-center text-gray-800 text-lg">
            Silahkan masukkan kode aktivasi di bawah ini
          </div>
          <div
            className={`mt-4 w-full justify-center ${
              isLoading ? "hidden" : "flex"
            }`}
          >
            <VerificationInput
              validChars="A-Za-z0-9"
              onComplete={(val) => checkCode(val)}
              length={8}
              classNames={
                isError
                  ? {
                      container: "gap-1 w-full",
                      character:
                        "h-10 w-9 md:h-14 md:w-14  uppercase rounded-lg border-red-600 flex items-center justify-center",
                      characterInactive: "bg-red-50",
                    }
                  : {
                      container: "gap-1 w-full",
                      character:
                        "h-10 w-9 md:h-14 md:w-14 uppercase rounded-lg border-gray-400 flex items-center justify-center",
                      characterInactive: "bg-white",
                    }
              }
            />
          </div>
          {isLoading && (
            <div className="flex items-center gap-4 py-2 justify-center mt-6">
              <Spinner /> Sedang memproses...
            </div>
          )}
          {isError && (
            <div className="py-2 mt-8 bg-red-50 px-4 rounded-lg text-sm text-left text-red-600">
              {errMessage}
            </div>
          )}
          <div className="py-2 mt-8 text-center">
            <label className="block mb-1">Jam dan zona waktu anda</label>
            <p className="m-0 text-xl font-semibold">
              <Clock withTimezone={true} />
            </p>

            <div className="bg-yellow-100 text-yellow-600 p-2 text-sm mt-4 block rounded-lg">
              <strong>Perhatian!</strong> <br />
              <span>
                Jam dan zona waktu anda harus sesuai, ketidaksesuaian waktu
                dapat menimbulkan kegagalan dalam mengakses soal asesmen.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
