import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Loading from "../layouts/loading";
import useAsesmen from "../stores/asesmen";


const GuestRoute = ({children}) => {
  const { isLoaded, fetchProgress, granted } = useAsesmen((state) => state);

  useEffect(() => {
    fetchProgress();
  }, []);

  if(isLoaded === false) {
    if(granted) {
      return <Navigate to="/challenge" />
    }
  } else {
    return <Loading text="Sedang memuat data..." />
  }

  return <>{children}</>
}

export default GuestRoute;