import { Cookies } from "react-cookie";
import create from "zustand";
import { request } from "../api/config";

const useAsesmen = create((set) => ({
  progres: {},
  session: {},
  granted: false,
  testData: {},
  isLoaded: false,
  isError: false,
  fetchProgress: async () => {
    set({ isLoaded: true });
    try {
      const res = await request.get("me");
      set({
        granted: await res.data.success,
        session: await res.data.data,
        progres: await res.data.progress,
        testData: await res.data.progress.data,
      });
    } catch (err) {
      set({
        isError: true,
        granted: false,
      });
      const cookies = new Cookies();
      cookies.remove("asesmenToken", { path: "/" });
    }
    set({ isLoaded: false });
  },
}));

export default useAsesmen;
