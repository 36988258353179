import { Cookies } from "react-cookie";
import { request } from "../api/config";

export const saveLogin = (data) => {
  const cookies = new Cookies();
  if (data.token)
    cookies.set("asesmenToken", data.token, { path: "/", maxAge: 21600 });
  else cookies.remove("asesmenToken", { path: "/" });
};

export const getAccessToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("asesmenToken");

  return token;
};

export const getMe = async () => {
  const cookies = new Cookies();
  try {
    const response = await request.post("me", {});
    return response;
  } catch (error) {
    cookies.remove("token", { path: "/" });
    return error;
  }
};

export const logout = () => {
  const cookies = new Cookies();
  cookies.remove("asesmenToken", { path: "/" });
};
