
const NotFoundPage = () => {
  return (
    <div className="w-full h-screen flex items-center text-teal-50 justify-center bg-teal-600">
      <div>
      <span className="text-7xl font-extrabold">404</span> <br /> <br />
      <span className="text-xl">Oops, Halaman ini tidak ada</span>
      </div>
    </div>
  )
}

export default NotFoundPage;