import React from "react";
import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "../pages/404";
import AsesmenIndex from "../pages/asesmen";
import Home from "../pages/home";
import GuestRoute from "./guest";
import PrivateRoute from "./private";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <GuestRoute>
          <Home />
        </GuestRoute>
    ),
  },
  {
    path: "/challenge",
    element: (
      <PrivateRoute>
        <AsesmenIndex /> 
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: (
      <NotFoundPage />
    ),
  },
  
]);


