import { Cookies } from "react-cookie";
import create from "zustand";
import { request } from "../api/config";

const useQuestion = create((set) => ({
  question: null,
  intro: null,
  isLoaded: false,
  isError: false,
  numbers: null,

  fetchQuestion: async (questionId) => {
    set({ isLoaded: true });
  },

  fetchNumbers: async (currentTestSessionId) => {
    set({ isLoaded: true });
    try {
      const res = await request.get(`get-num-progress/${currentTestSessionId}`);
      set({
        numbers: await res.data.data,
      });
    } catch (err) {
      set({
        isError: true,
      });
    }
    set({ isLoaded: false });
  },
}));

export default useQuestion;
