import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Routes";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "F12") {
        event.preventDefault();
      }
    });
    document.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });
    document.addEventListener("copy", (event) => {
      alert("Copy Paste function is disabled");
      return false;
    });
    document.addEventListener("paste", (event) => {
      alert("Copy Paste function is disabled");
    });
  });

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
